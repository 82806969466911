<div
  (click)="handleClick()"
  class="quick-action-card"
  [class.disabled]="isDisabled"
  [class.neutral]="isNeutral"
>
  <div class="quick-action-card-inner">
    <div class="quick-action-card-icon" *ngIf="icon">
      <i nz-icon [nzType]="icon"></i>
    </div>
    <div class="quick-action-card-text" *ngIf="title">
      {{ title }}
      <i nz-icon nzType="feather/chevron-right"></i>
    </div>
    <div class="quick-action-card-subtitle" *ngIf="description">
      {{ description }}
    </div>
  </div>
</div>
