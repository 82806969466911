import { Params } from '@angular/router';

// 3rd party
import {
  Exclude,
  Expose,
  instanceToInstance,
  instanceToPlain,
  plainToClass,
  Transform,
  Type
} from 'class-transformer';
import { ISlugMailingMetadata } from './ISlugMetadata';

// App
import { IFont } from '../interfaces';
import { ILandingPage, LandingPage } from './landing-pages';
import { hashString, transformTimestampToDate } from '../tools';
import { IImage, IResponsiveImage } from './IImage';
import { DEFAULT_LANDING_PAGE } from '../constants';
import { ISlugGeneralSettings } from './general';

export class ISlugTrackers {
  facebook?: {
    pixelId?: string;
    embedCode?: string;
    pixelImage?: string;
  };
  tiktok?: {
    pixelId?: string;
    embedCode?: string;
  };
  adroll?: {
    advertisingId?: string;
    pixelId?: string;
    embedCode?: string;
  };
}

export class IAccountInfo {
  title?: string;
  avatarUrl?: string;
  pronouns?: string;
  bio?: string;
  location?: string;
  links?: {
    website?: string;
    instagram?: string;
    twitter?: string;
    linkedIn?: string;
    facebook?: string;
    youtube?: string;
    tiktok?: string;
    soundcloud?: string;
    clubhouse?: string;
    twitch?: string;
    spotify?: string;
    apple?: string;
    lastfm?: string;
  };
}

export class ISlug {
  // Public account metadata
  slug!: string;
  defaultDomain?: string;
  domains!: string[];
  linkCount?: number;
  mailing?: ISlugMailingMetadata;
  sms?: {
    phoneNumbers?: string[];
    primaryPhoneNumber?: string;
    alphaSender?: string;
  };
  general?: ISlugGeneralSettings;

  @Type(() => Theme)
  theme?: Theme;

  trackers?: ISlugTrackers;
  accountInfo?: IAccountInfo;
  customCode?: {
    css?: string;
    js?: string;
  };

  static fromObject(object: ISlug) {
    return plainToClass(ISlug, object);
  }
}

export class ICardStyle {
  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  name?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  borderRadius?: number;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  borderWidth?: number;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  dropShadow?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  backgroundColor?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  textColor?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  borderColor?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  imagePosition?: 'left' | 'right' | 'top' | 'bottom';

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  textPosition?: 'left' | 'right' | 'center';
}

export class IButtonStyle {
  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  name?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  borderRadius?: number;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  borderWidth?: number;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  dropShadow?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  backgroundColor?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  textColor?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  borderColor?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  hoverBackgroundColor?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  hoverTextColor?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  hoverBorderColor?: string;
}

export class IBaseElementStyle {
  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  backgroundColor?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  textColor?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  borderRadius?: number;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  dropShadow?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  borderColor?: string;
}

export class IThemeHeader {
  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  imageColor?: string;
}

export class IThemeMetadata {
  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  empty?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  favicon?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  faviconThumbnail?: string;
}

export class IThemeLayout {
  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  backgroundImage?: IImage;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  responsiveBackgroundImage?: IResponsiveImage;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  previewImage?: IImage;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  responsivePreviewImage?: IResponsiveImage;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  backgroundGradient?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  backgroundColor?: string;
}

export class IThemeFonts {
  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  font!: IFont;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  h1?: IFont;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  h2?: IFont;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  h3?: IFont;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  h4?: IFont;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  h5?: IFont;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  h6?: IFont;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  p?: IFont;
}

export class IThemeText {
  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  color?: string;
}

export class IThemeLink {
  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  textColor?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  hoverTextColor?: string;
}

export class IThemeDTO {
  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  id?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  metadata?: IThemeMetadata;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  header?: IThemeHeader;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  layout?: IThemeLayout;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  fonts?: IThemeFonts;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  text?: IThemeText;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  link?: IThemeLink;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  card?: ICardStyle;

  @Expose()
  @Transform((val) => val.value ?? [], { toClassOnly: true })
  customCards?: ICardStyle[];

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  button?: IButtonStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  secondaryButton?: IButtonStyle;

  @Expose()
  @Transform((val) => val.value ?? [], { toClassOnly: true })
  customButtons?: IButtonStyle[];

  @Expose()
  @Transform((val) => val.value ?? [], { toClassOnly: true })
  palette?: string[];

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  tooltip?: IBaseElementStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  dropdown?: IBaseElementStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  snackbar?: IBaseElementStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  textInput?: IBaseElementStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  select?: IBaseElementStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  checkbox?: IBaseElementStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  name?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  description?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  image?: IImage;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  responsiveImage?: IResponsiveImage;
}

export class ISlugRequestBase {
  id?: string;
  slug!: ISlug;
  handle?: string;
  email?: string;
  name?: string;
  businessTitle?: string;
  teamSize?: string;
  category?: string;
  customCategory?: string;
  journeyStage?: string;
  customJourneyStage?: string;
  intent?: string;
  customIntent?: string;
  questions?: string;
  integrations?: string[];
  timezone?: string;
  referrer?: {
    slug?: string;
    general?: string;
  };
  funnel?: string;
  params?: Params;
}

export class SlugRequest extends ISlugRequestBase {
  @Exclude()
  protected _hash: number;

  @Type(() => LandingPage)
  collection!: LandingPage;

  get hash() {
    if (!this._hash) {
      const page = this.collection?.hash;
      const slug = JSON.stringify(this.slug);
      this._hash = hashString(`${page}${slug}${this.name}`);
    }

    return this._hash;
  }

  static new() {
    return plainToClass(SlugRequest, { collection: DEFAULT_LANDING_PAGE });
  }

  static fromObject(object: ISlugRequestBase) {
    return plainToClass(SlugRequest, object);
  }

  updateProperties(properties: Partial<ISlugRequestBase>) {
    for (const key in properties) {
      this[key] = properties[key];
    }
    this._hash = null;
  }

  clone(withSlugRequest?: Partial<SlugRequest>) {
    const clone = instanceToInstance<SlugRequest>(this);
    if (withSlugRequest) {
      for (const key in withSlugRequest) {
        clone[key] = withSlugRequest[key];
      }
      clone._hash = null;
    }
    return clone;
  }

  toObject() {
    return instanceToPlain(this) as ISlugRequestBase;
  }
}

export class ISlugRequest extends ISlugRequestBase {
  files?: any[];
  hasBeenProvisioned?: boolean;
  hasBeenOnboarded?: boolean;
  hubSpotSlugRequestObjectId?: string;
  hubSpotObjectGenerationFailureReason?: string;
  slugsProvisioned?: string[];
  slugsOnboarded?: string[];
  priority: boolean;
  features?: string[];
  captchaScore?: number;
  flagged?: boolean;

  @Transform(transformTimestampToDate, { toClassOnly: true })
  createdAt?: Date;
}

export type PublishState =
  (typeof PUBLISHED_STATES)[keyof typeof PUBLISHED_STATES];

const PUBLISHED: 'published' = 'published';
const ARCHIVED: 'archived' = 'archived';
const DRAFT: 'draft' = 'draft';

export const PUBLISHED_STATES = {
  PUBLISHED,
  ARCHIVED,
  DRAFT
};

export class Theme extends IThemeDTO {
  @Exclude()
  protected _hash: number;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  id: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  card?: ICardStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  metadata?: IThemeMetadata;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  header?: IThemeHeader;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  text?: IThemeText;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  link?: IThemeLink;

  @Expose()
  @Transform((val) => val.value ?? [], { toClassOnly: true })
  customCards?: ICardStyle[];

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  button?: IButtonStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  secondaryButton?: IButtonStyle;

  @Expose()
  @Transform((val) => val.value ?? [], { toClassOnly: true })
  customButtons?: IButtonStyle[];

  @Expose()
  @Transform((val) => val.value ?? [], { toClassOnly: true })
  palette?: string[];

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  tooltip?: IBaseElementStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  dropdown?: IBaseElementStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  snackbar?: IBaseElementStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  textInput?: IBaseElementStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  select?: IBaseElementStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  checkbox?: IBaseElementStyle;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  name?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  description?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  image?: IImage;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  responsiveImage?: IResponsiveImage;

  @Expose()
  @Transform((val) => val.value ?? [], { toClassOnly: true })
  tags?: string[];

  @Expose()
  @Type(() => ThemeLayout)
  layout?: ThemeLayout;

  @Expose()
  @Type(() => ThemeFonts)
  fonts?: ThemeFonts;

  get hash() {
    if (!this._hash) {
      this._hash = hashString(JSON.stringify(this.toObject()));
    }

    return this._hash;
  }

  static new() {
    return plainToClass(Theme, {});
  }

  static fromObject(object: IThemeDTO) {
    return plainToClass(Theme, object);
  }

  clone(withTheme?: Partial<IThemeDTO>) {
    const clone = instanceToInstance<Theme>(this);
    if (withTheme) {
      for (const key in withTheme) {
        clone[key] = withTheme[key];
      }
      clone._hash = null;
    }
    return clone;
  }

  updateProperties(properties: Partial<Theme>) {
    for (const key in properties) {
      this[key] = properties[key];
    }
    this._hash = null;
  }

  toObject() {
    return instanceToPlain(this) as IThemeDTO;
  }
}

export class ThemeLayout extends IThemeLayout {
  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  responsiveBackgroundImage?: IResponsiveImage;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  previewImage?: IImage;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  responsivePreviewImage?: IResponsiveImage;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  backgroundImage?: IImage;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  backgroundGradient?: string;

  @Expose()
  @Transform((val) => val.value ?? null, { toClassOnly: true })
  backgroundColor?: string;

  static fromObject(object: IThemeLayout) {
    return plainToClass(ThemeLayout, object);
  }
}

export class ThemeFonts extends IThemeFonts {
  @Exclude()
  private _hash: number;

  get hash() {
    if (!this._hash) {
      this._hash = hashString(JSON.stringify(this.toObject()));
    }

    return this._hash;
  }

  toObject() {
    return instanceToPlain(this);
  }
}

export class EditableTheme extends Theme {
  @Expose()
  @Transform((val) => val.value ?? false, { toClassOnly: true })
  published = false;

  @Expose()
  @Transform((val) => val.value ?? false, { toClassOnly: true })
  archived = false;

  static new() {
    return plainToClass(EditableTheme, {});
  }

  static fromObject(object: IThemeDTO) {
    return plainToClass(EditableTheme, object);
  }

  clone(withTheme?: Partial<IThemeDTO>) {
    const clone = instanceToInstance<EditableTheme>(this);
    if (withTheme) {
      for (const key in withTheme) {
        clone[key] = withTheme[key];
      }
      clone._hash = null;
    }
    return clone;
  }

  toObject() {
    return instanceToPlain(this) as IThemeDTO;
  }
}
