import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  standalone: false,
  selector: 'lib-tile-button-view',
  templateUrl: './tile-button-view.component.html',
  styleUrls: ['./tile-button-view.component.less']
})
export class TileButtonViewComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() isDisabled = false;
  @Input() tileStyle: 'default' | 'neutral' = 'default';
  @Output() onClickButton = new EventEmitter<void>();

  constructor() {}

  get isNeutral() {
    return this.tileStyle == 'neutral';
  }

  handleClick() {
    if (!this.isDisabled) {
      this.onClickButton.emit();
    }
  }
}
